<template>
    <div class="modelSwitching">
        <img src="@/images/loginBanner.png" alt="" class="banner" />
        <div class="modelSwitchingCon">
            <div class="item socks" @click="gotosocks">
                <img src="@/newImg/socks@2x.png" alt="">
                <span>{{ $t('User.txt30') }}</span>
            </div>
            <div class="item clothing">
                <img src="@/newImg/shoes@2x.png" alt="">
                <span>{{ $t('User.txt31') }}</span>
            </div>
            <div class="item shoes">
                <img src="@/newImg/clothing@2x.png" alt="">
                <span>{{ $t('User.txt32') }}</span>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "modelSwitching",
    data() {
        return {}
    },
    methods: {
        gotosocks(){
            localStorage.setItem("leftNavtype",2)
            this.$router.push({
                path:'/oneIndex'
            })
        }
    }
};
</script>
  
<style scoped lang="less">
@import url("./modelSwitching.less");
</style>
  